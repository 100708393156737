<template>
    <FilterForm :formData="filterData" :filters="filters" @changeDept="changeDept" @submit="handleSubmit"
        @reset="handleReset" />
    <div class="echarts-box">
        <div id="myEcharts" ref="liveChart" :style="{ width: '100%', height: '400px' }"></div>
    </div>
</template>
 
<script>
import { reset } from "@/until";
import FilterForm from "@/components/filter";
import { useStore } from "vuex";
//import * as echarts from "echarts";
import { onMounted, onUnmounted, ref, reactive,shallowRef } from "vue";
import { getVisitCharts } from "@/api/method/customer-visit";
import { getUserByDept } from "@/api/method/common";

// 引入echarts
import * as echarts from "echarts";
// 引入提示框，标题，直角坐标系等组件，组件后缀都为 Component
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
} from "echarts/components";
// 引入图表类型，图表后缀都为 Chart
import { BarChart } from "echarts/charts";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    BarChart,
    CanvasRenderer,
]);
export default {
    name: "echartsBox",
    components: {
        FilterForm
    },
    setup() {
        const { dispatch, state } = useStore();
        /// 声明定义一下echart
        // let echart = echarts;
        const liveChart = shallowRef(null);
        const myChart = shallowRef(null);
        /**
         * @description: 拆箱函数,解决tooltip显示问题
         * @param {*}
         * @return {*}
         */
        const unwarp = function (obj) {
            return obj && (obj.__va_raw || obj.valueOf() || obj);
        };

        onMounted(() => {
            getList();
            getDepartList();
        });

        onUnmounted(() => {
            myChart.value.dispose;
        });
        const filterData = reactive({
            deptId: null,
            userId: null,
            date: null,
        });
        const filters = reactive([
            [
                {
                    label: "选择部门",
                    type: "select",
                    attr: "deptId",
                    placeholder: "请选择",
                    column: 6,
                    option: [],
                    labelWidth: "80px",
                },
                {
                    label: "选择顾问",
                    type: "select",
                    attr: "userId",
                    placeholder: "请选择",
                    column: 6,
                    option: [],
                    labelWidth: "100px",
                },
                {
                    label: "上门时间",
                    type: "dateRange",
                    attr: "date",
                    column: 8,
                    labelWidth: "80px",
                    valueFormat: "YYYY-MM-DD",
                },
                {
                    type: "button",
                    column: 4,
                },
            ],
        ]);
        const tableData = ref([])
        const getList = async () => {
            const { date, ...param } = filterData;
            const startTime = date && date[0];
            const endTime = date && date[1];

            const res = await getVisitCharts({
                ...param,
                startTime,
                endTime,
            })
            // console.log(res)
            if (res?.code == 200) {
                //  console.log("getVisitCharts", res)
                const series =ref([]); 
                const star=[0,1,2,3,4,4.5,5];
                star.forEach(item =>{
                   const currentObj= res.data.filter(m => m.starStatus==item);
                   if(currentObj.length>0){
                    series.value.push(currentObj[0].count);
                   }
                   else{
                    series.value.push(0);
                   }
                });
                initChart(series.value);
            }
            // tableData.value= 
        };
        const getDepartList = async () => {
            await dispatch("departmentList");
            filters[0][0].option = state.departmentList.map((item) => ({
                label: item.fullName,
                val: item.id,
            }));
        };
        const changeDept = async (id) => {
            const res = await getUserByDept(id);
            filters[0][1].option = res.data.map((item) => ({
                label: item.nickname,
                val: item.id,
            }));
        };

        const handleSubmit = () => getList();
        const handleReset = () => {
            reset(filterData);
            getList();
        };
        // 基础配置一下Echarts
        function initChart(series) {

            myChart.value = echarts.init(liveChart.value);
            // console.log("myChart.value", myChart.value)
            // 把配置和数据放这里
            unwarp(myChart.value).setOption({
                xAxis: {
                    type: "category",
                    data: [
                        "0星客户",
                        "1星客户",
                        "2星客户",
                        "3星客户",
                        "4星客户",
                        "4+星客户",
                        "5星客户"
                    ]
                },
                label: { show: true },
                title: {
                    text: '来访星级统计图'
                },
                tooltip: {
                    trigger: "axis"
                },
                yAxis: {
                    type: "value"
                },
                series: [
                    {
                        data: series,
                        type: "bar",
                        smooth: true
                    }
                ]
            });
            window.onresize = function () {
                //自适应大小
                myChart.value.resize();
            };
        }

        return {
            initChart,
            tableData,
            filters,
            filterData,
            changeDept,
            handleSubmit,
            handleReset,
            liveChart,
            myChart
        };
    }
};
</script>
